import {
  AppEntityHydrated,
  AssetEntityHydrated,
  EntityId,
  LambdaAbVariantEntity,
} from "@jackfruit/common"
import { DOMParser } from "@xmldom/xmldom"
import { trim } from "lodash"
import React from "react"
import { Helmet } from "react-helmet"
import Scroll from "react-scroll"
import { useEffectOnce } from "react-use"
import { useCanonical } from "~/hooks/useCanonical"
import { useMetaDescription } from "~/hooks/useMetaDescription"
import { useMetaTitle } from "~/hooks/useMetaTitle"
import { useOpenGraphImage } from "~/hooks/useOpenGraphImage"
import { useTemplateTextFonts } from "~/hooks/useTemplateTextFonts"
import { GA4fireAbTestAttribute } from "~/services/GA4"

const isDev = process.env.GATSBY_ACTIVE_ENV === "dev"

interface Props {
  config: AppEntityHydrated
  lambdaAbExperimentId?: string
  pageId?: EntityId
  seo?: {
    title?: string
    description?: string
    featureImage?: AssetEntityHydrated
    canonical?: string
  }
}
/**
 * Todo: Gatsby doc suggests us to use Gatsby Head api instead of Helmet
 * more info: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

const Head: React.FC<Props> = ({
  config,
  lambdaAbExperimentId,
  pageId,
  seo,
}) => {
  const {
    baseUrl,
    language,
    openGraphTitle,
    openGraphDescription,
    settings: {
      abTestAttribute,
      googleMapKey,
      mapProvider,
      lambdaAbEnabled,
      lambdaAbExperimentId: globalExperimentId,
      lambdaAbVariants,
      rawHead,
      gaProjectId,
      gaMeasurementId,
      facebookMeasurementId,
      microsoftMeasurementId,
      pinterestMeasurementId,
      tiktokMeasurementId,
    },
  } = config

  const { fonts } = useTemplateTextFonts()

  // allow title and description override
  const [metaTitleOverride] = useMetaTitle(pageId ?? "0")
  const [metaDescriptionOverride] = useMetaDescription(pageId ?? "0")
  // allwo open graph image override
  const [openGraphImageOverride] = useOpenGraphImage(pageId ?? "0")
  // allow specific pages to force canonical
  const [canonical] = useCanonical(pageId ?? "0")
  const canonicalToUse = canonical || seo?.canonical

  const templateFonts = fonts
    ?.filter(({ ttf }) => {
      return ttf.fontFamily !== "Poppins"
    })
    .map(
      ({ ttf, woff2 }) => `
      @font-face {
        font-family: '${ttf.fontFamily}';
        font-style: ${ttf.fontStyle};
        font-weight: ${ttf.fontWeight};
        font-display: swap; 
        src: url(${ttf.src}) format('ttf');
      }
      @font-face {
        font-family: '${woff2.fontFamily}';
        font-style: ${woff2.fontStyle};
        font-weight: ${woff2.fontWeight};
        src: url(${woff2.src}) format('woff2');
        font-display: swap; 
        unicode-range: ${woff2.unicodeRange.join(" ")};
      }
  `
    )
    ?.join("")

  const baseUrlRedirect = trim(baseUrl, "/")
  const metaTitle = metaTitleOverride || seo?.title || openGraphTitle
  const metaDescription =
    metaDescriptionOverride || seo?.description || openGraphDescription

  const openGraphImagePath =
    seo?.featureImage?.path || openGraphImageOverride.url
  const openGraphImageWidth =
    seo?.featureImage?.width || openGraphImageOverride.width
  const openGraphImageHeight =
    seo?.featureImage?.height || openGraphImageOverride.height

  const parser = new DOMParser()
  const doc = Boolean(rawHead)
    ? parser.parseFromString(rawHead, "text/html")
    : ({} as Document)
  const rawScriptList = Boolean(rawHead)
    ? Array.from(doc.getElementsByTagName("script"))
    : []
  const rawStyle = Boolean(rawHead)
    ? doc.getElementsByTagName("style")[0]?.textContent
    : ""

  const isPreview = config.deployment?.type === "preview"
  const experimentId = globalExperimentId || lambdaAbExperimentId

  useEffectOnce(() => {
    if (window) {
      // Bootstrap GTM
      window.dataLayer = window.dataLayer ?? []

      // Global scroller for custom
      window.scrollToElement = (elementName: string) => {
        const scroller = Scroll.scroller
        scroller.scrollTo(elementName, {
          duration: 500,
          smooth: true,
          spy: true,
        })
      }
    }

    if (lambdaAbEnabled && experimentId) {
      let abTestVariantId: number = NaN
      if (config.deployment) {
        abTestVariantId = (lambdaAbVariants as LambdaAbVariantEntity[])
          .sort((a, b) => (a.id as number) - (b.id as number))
          .findIndex(
            lambdaAbVariant =>
              lambdaAbVariant.id === config.deployment.lambdaAbVariantId
          )
      }

      const abCookieAttribute =
        document.cookie.match(/X-Source=(.*?)(;|$)/)?.[1]
      const abTestAttr = abCookieAttribute ?? abTestAttribute

      GA4fireAbTestAttribute(abTestAttr, experimentId, abTestVariantId)
    }
  })

  const shouldMountGtmGA4 = Boolean(gaProjectId && !isDev)

  return (
    <Helmet>
      <html lang={language || "en-US"} translate="no" />
      <title>{metaTitle}</title>
      <meta name="viewport" content="width=device-width,user-scalable=no" />
      <meta name="name" content={metaTitle} />
      <meta name="description" content={metaDescription} />

      {Boolean(openGraphImagePath) && (
        <meta property="og:image" content={openGraphImagePath} />
      )}
      {Boolean(openGraphImageWidth) && (
        <meta property="og:image:width" content={`${openGraphImageWidth}`} />
      )}
      {Boolean(openGraphImageHeight) && (
        <meta property="og:image:height" content={`${openGraphImageHeight}`} />
      )}

      {Boolean(canonicalToUse) && (
        <link rel="canonical" href={canonicalToUse} />
      )}

      <base href={!isDev ? baseUrl : "/"} />

      <script>
        {`
          if (!window.scrollToElement) {
            window.scrollToElement = function (elementName) {
              // do nothing
            }
          }
        `}
      </script>

      {gaMeasurementId && (
        <script>{`
          window.dataLayer = window.dataLayer ?? []

          function gtag() {
            dataLayer.push(arguments);
          }
          dataLayer.push({
            GTM_VAR_GA4_MEASUREMENT_ID: "${
              isPreview
                ? config.deployment.previewGaMeasurementId
                : gaMeasurementId
            }",
            FB_VAR_MEASUREMENT_ID: "${facebookMeasurementId}",
            MICROSOFT_VAR_MEASUREMENT_ID: "${microsoftMeasurementId}",
            PIN_VAR_MEASUREMENT_ID: "${pinterestMeasurementId}",
            TIKTOK_VAR_MEASUREMENT_ID: "${tiktokMeasurementId}",
          })
        `}</script>
      )}

      {shouldMountGtmGA4 && (
        <script
          async
        >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gaProjectId}');`}</script>
      )}

      <script>{`
          if (
            window.location.href.indexOf("${baseUrlRedirect}") !== 0 &&
            window.location.href.indexOf("localhost") === -1
          ) {
            window.location.href = "${baseUrlRedirect}" + window.location.search
          }
        `}</script>

      {Boolean(templateFonts) && <style>{templateFonts}</style>}

      {mapProvider === "googleMaps" && googleMapKey && (
        <script
          async
          type="text/javascript"
          src={`https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&libraries=places,visualization`}
        />
      )}
      {Boolean(rawStyle) && <style type="text/css">{rawStyle}</style>}
      {rawScriptList.length > 0 &&
        rawScriptList?.map((raw, index) => {
          const srcUrl = raw.getAttribute("src") as string
          return srcUrl ? (
            <script key={index} src={srcUrl} async></script> //Support CDN
          ) : (
            <script key={index}>{raw.textContent}</script>
          )
        })}
    </Helmet>
  )
}

export default Head
