import { Link as ChakraLink, List, ListItem } from "@chakra-ui/react"
import {
  NavigationEntityHydrated,
  NavigationLinkEntityHydrated,
  PageEntityHydrated,
} from "@jackfruit/common"
import arrayToTree from "array-to-tree"
import { Link as GatsbyLink } from "gatsby"
import React, { useMemo } from "react"
import { addPagePathToNavigationLink } from "~/services/Utils"
import HtmlAddon from "../navigation/HtmlAddon"

interface NavigationLinkProps {
  link: NavigationLinkEntityHydrated
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ link }) => {
  const {
    id,
    type,
    path,
    label,
    external,
    navigationId,
    labelPrefixHtml,
    labelSuffixHtml,
  } = link

  switch (type) {
    case "link":
      if (external) {
        return (
          <ListItem
            id={`navigation-${navigationId}-item-${id}`}
            className={type}
          >
            <HtmlAddon placement={"left"} html={labelPrefixHtml} />
            <ChakraLink href={path} rel="noopener nofollow" isExternal>
              {label}
            </ChakraLink>
            <HtmlAddon placement={"right"} html={labelSuffixHtml} />
          </ListItem>
        )
      } else {
        return (
          <ListItem
            id={`navigation-${navigationId}-item-${id}`}
            className={type}
          >
            <HtmlAddon placement={"left"} html={labelPrefixHtml} />
            <GatsbyLink to={`${path}`} rel="noopener nofollow">
              {label}
            </GatsbyLink>
            <HtmlAddon placement={"right"} html={labelSuffixHtml} />
          </ListItem>
        )
      }
    case "page":
      return (
        <ListItem id={`navigation-${navigationId}-item-${id}`} className={type}>
          <HtmlAddon placement={"left"} html={labelPrefixHtml} />
          <GatsbyLink to={`/${path}`} rel="noopener nofollow">
            {label}
          </GatsbyLink>
          <HtmlAddon placement={"right"} html={labelSuffixHtml} />
        </ListItem>
      )
    case "text":
      return (
        <ListItem id={`navigation-${navigationId}-item-${id}`} className={type}>
          <HtmlAddon placement={"left"} html={labelPrefixHtml} />
          {label}
          <HtmlAddon placement={"right"} html={labelSuffixHtml} />
          <List listStyleType="disc">
            {link.children?.map(subLink => {
              return <NavigationLink key={subLink.id} link={subLink} />
            })}
          </List>
        </ListItem>
      )

    default:
      return null
  }
}

interface Props {
  navigation: NavigationEntityHydrated
  pages: PageEntityHydrated[]
  extraClasses?: string[]
}

const Navigation: React.FC<Props> = ({
  navigation,
  pages,
  extraClasses = [],
}) => {
  const navigationTree = useMemo(() => {
    const orderedLinks = addPagePathToNavigationLink(navigation.links, pages)
    const tree = arrayToTree(orderedLinks, {
      parentProperty: "parentId",
    })
    return tree
  }, [navigation.links, pages])

  const classNames = ["navigation", ...extraClasses]

  return (
    <List
      id={`navigation-${navigation.id}`}
      className={classNames.join(" ")}
      listStyleType="none"
    >
      {navigationTree.map(link => {
        return <NavigationLink key={link.id} link={link} />
      })}
    </List>
  )
}

export default Navigation
