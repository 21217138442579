import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Wrapper from "../../../components/Wrapper"
import Head from "../Head"
import Footer from "./Footer"
import Header from "./Header"

interface Props {
  children: React.ReactElement
}

const getPageSeo = (configJson: any, pathname: string) => {
  const {
    displayName,
    accountPageTitle,
    accountPageDescription,
    registerPageTitle,
    registerPageDescription,
    forgotPasswordPageTitle,
    forgotPasswordPageDescription,
    changePasswordPageTitle,
    changePasswordPageDescription,
  } = configJson

  if (!pathname) {
    return null
  }

  const pageSeoMap: Record<string, { title: string; description: string }> = {
    "change-password": {
      title: changePasswordPageTitle || `Change Password | ${displayName}`,
      description:
        changePasswordPageDescription || `Change Password | ${displayName}`,
    },
    account: {
      title: accountPageTitle || `Account | ${displayName}`,
      description: accountPageDescription ?? `Account | ${displayName}`,
    },
    register: {
      title: registerPageTitle || `Register | ${displayName}`,
      description: registerPageDescription ?? `Register | ${displayName}`,
    },
    "forgot-password": {
      title: forgotPasswordPageTitle || `Forgot Password | ${displayName}`,
      description:
        forgotPasswordPageDescription || `Forgot Password | ${displayName}`,
    },
  }

  // normalizedPath is the pathname without leading and trailing slashes
  // /register/ => @return: register
  // /account/change-password/ => @return: change-password
  const pathnameKeys = pathname.split("/").filter(Boolean)
  const lastPathName = pathnameKeys.pop() || ""

  return pageSeoMap[lastPathName] || null
}

const Layout: React.FC<Props> = ({ children }) => {
  const { configJson } = useStaticQuery(configJsonQuery)
  const {
    props: { pageContext, location },
  } = children
  const seoObj = getPageSeo(configJson, location.pathname) ?? pageContext?.seo
  return (
    <>
      <Head config={configJson} seo={seoObj} />
      <Header config={configJson} />
      <Wrapper py={{ base: 5, lg: 10 }}>{children}</Wrapper>
      <Footer config={configJson} />
    </>
  )
}

export default Layout

export const configJsonQuery = graphql`
  query GetConfig {
    configJson {
      id
      displayName
      name
      slug
      baseUrl
      termsAndConditionsPageId
      privacyPolicyPageId
      faviconImageId
      logoImageId
      iosStoreId
      iosStoreUrl
      iosStoreName
      iosStoreRating
      iosStoreRatingCount
      iosDisplayStoreBannerOnMobile
      androidStoreUrl
      androidStoreName
      androidStoreRating
      androidStoreRatingCount
      androidDisplayStoreBannerOnMobile
      blockOrderSummaryAppInstallEnabled
      blockOrderSummaryAppInstallQrCode
      blockOrderSummaryAppInstallQrCodeUrl
      blockOrderSummaryAppInstallAppScreenImageId
      blockOrderSummaryAppInstallContent
      blockOrderSummaryAppInstallAppScreenImage {
        id
        filename
        path
        hash
        mimeType
        width
        height
        size
      }
      openGraphTitle
      openGraphDescription
      openGraphLogo
      openGraphSitemapXml
      storesPageTitle
      storesPageDescription
      accountPageTitle
      accountPageDescription
      registerPageTitle
      registerPageDescription
      forgotPasswordPageTitle
      forgotPasswordPageDescription
      changePasswordPageTitle
      changePasswordPageDescription
      defaultLocale
      defaultCurrency
      additionalLocaleUrls {
        displayName
        url
      }
      language
      common {
        id
        appId
        hideHeader
        hideFooter
        showPoweredByAutopilotBanner
        poweredByAutopilotBannerText
        poweredByAutopilotRedirectUrl
        poweredByAutopilotBg
        poweredByAutopilotTextColour
        showSocialMediaLinks
      }
      faviconImage {
        id
        filename
        path
        hash
        mimeType
        width
        height
        size
      }
      logoImage {
        id
        filename
        path
        hash
        mimeType
        width
        height
        size
      }
      navigations {
        id
        name
        appId
        links {
          id
          label
          labelPrefixHtml
          labelSuffixHtml
          path
          parentId
          navigationId
          navigationMegamenuId
          pageId
          page {
            id
            name
            appId
            order
            slug
            isDeliveryEnabled
            isPickupEnabled
            defaultFulfillment
          }
          type
          external
          order
          megamenu {
            id
            name
            columns {
              id
              title
              order
              navigationMegamenuId
              cells {
                id
                title
                type
                html
                navigationMegamenuColumnId
                order
                links {
                  id
                  external
                  label
                  navigationMegamenuCellId
                  order
                  pageId
                  path
                  type
                  page {
                    id
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
      pages {
        parentId
        id
        name
        appId
        order
        slug
        parentId
        blocks {
          id
          pageId
          type
          elementId
          order
          inherit
          footer {
            id
            blockId
            row1Col1Html
            row1Col2Html
            row2Col1Html
            row2Col2Html
            row2Col3Html
            row2Col4Html
            row3Col1Html
            row1Bg
            row1TextColor
            row2Bg
            row2TextColor
            row3Bg
            row3TextColor
            footerType
            countrySelectorConfiguration {
              url
              label
            }
            showCountrySelector
            showAppButtons
            navigationMegamenuId
            megamenu {
              id
              name
              columns {
                id
                title
                order
                navigationMegamenuId
                cells {
                  id
                  title
                  type
                  html
                  navigationMegamenuColumnId
                  order
                  links {
                    id
                    external
                    label
                    badgeText
                    badgeColor
                    navigationMegamenuCellId
                    order
                    pageId
                    path
                    type
                    page {
                      id
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
          header {
            id
            blockId
            topNavigationBackgroundColor
            topNavigationColor
            brandNavigationBackgroundColor
            brandNavigationColor
            brandNavigationId
            mainNavigationBackgroundColor
            mainNavigationColor
            mainNavigationId
            mobileNavigationColor
            mobileNavigationId
            callToActionHtml
            displaySiteWideBanner
            siteWideBannerContent
            brandNavigation {
              id
              name
              appId
              links {
                id
                label
                labelPrefixHtml
                labelSuffixHtml
                parentId
                navigationId
                pageId
                type
                order
                path
                page {
                  id
                  name
                  appId
                  order
                  slug
                  isDeliveryEnabled
                  isPickupEnabled
                  defaultFulfillment
                }
              }
            }
            mainNavigation {
              id
              name
              appId
              links {
                id
                label
                labelPrefixHtml
                labelSuffixHtml
                parentId
                navigationId
                pageId
                type
                order
                path
                page {
                  id
                  name
                  appId
                  order
                  slug
                  isDeliveryEnabled
                  isPickupEnabled
                  defaultFulfillment
                }
              }
            }
            mobileNavigation {
              id
              name
              appId
              links {
                id
                label
                labelPrefixHtml
                labelSuffixHtml
                parentId
                navigationId
                pageId
                type
                order
                page {
                  id
                  name
                  appId
                  order
                  slug
                  isDeliveryEnabled
                  isPickupEnabled
                  defaultFulfillment
                }
              }
            }
            topNavigation {
              id
              name
              appId
              links {
                id
                label
                labelPrefixHtml
                labelSuffixHtml
                navigationId
                pageId
                type
                order
                page {
                  id
                  name
                  appId
                  order
                  slug
                  isDeliveryEnabled
                  isPickupEnabled
                  defaultFulfillment
                }
              }
            }
          }
        }
        defaultPickupTerritory
        defaultDeliveryTerritory
        pageSeo {
          id
          pageId
          openGraphTitle
          openGraphDescription
          openGraphLogo
          openGraphFeatureImageId
          openGraphSitemapXml
          openGraphFeatureImage {
            id
            filename
            path
            hash
            mimeType
            width
            height
            size
          }
        }
      }
      settings {
        id
        appId
        remoteApiUrl
        remoteApiClientApiKey
        recaptchaSiteKey
        googleMapKey
        addressAutoComplete
        loggingMode
        mapProvider
        rawHead
        isUserAccountEnabled
        loggingMode
        googleOptimizeExperimentId
        gaProjectId
        gaMeasurementId
        facebookMeasurementId
        microsoftMeasurementId
        pinterestMeasurementId
        tiktokMeasurementId
      }
    }
  }
`
